import react, { useEffect, useState, useRef } from 'react';
import { Redirect, Link, useHistory, useParams } from "react-router-dom";
import Korotu from '../../images/korotu-navbar-logo.png'
import './navigation-bar.css';
import SignoutDark from '../../images/icons/dark-signout-icon.png'
import Logo from "../../images/Korotu_Logo.png"
import Korotu_logo from "../../images/korotu_nav.png"
import User from '../../images/icons/user-icon.png'
import { CORE_PORT, url } from '../../apis/landsteward/urlconfig';
import bell from '../../images/icons/bell-icon.png'
import { ProjectSelect } from '../projects/project';
import { getProjectDetails, getProjectRecentActivity } from '../../apis/landsteward/accounts';
import { getProjectNotifications, postProjectActivityRead } from '../../apis/landsteward/accounts';
import { getGeneratingReportNames } from '../../apis/landsteward/mowing';

let account_id_global = "";

export class NavigationBar extends react.Component {
  constructor(props) {
    super(props);
    account_id_global = props.account_id
    // console.log("These are the props", props)
    // console.log(this.props.account_id)
    this.userLogout = this.userLogout.bind(this);
  }


  async userLogout() {
    const generating_report_names = await getGeneratingReportNames();
    let logout;

    if (generating_report_names === null) {
      logout = window.confirm("Are you sure you wish to sign out? This will cancel any reports that are not finished generating.")
    } else if (generating_report_names.length === 0) {
      logout = true;
    } else {
      logout = window.confirm(`Are you sure you wish to sign out? This will cancel ${generating_report_names.length} currently generating report(s): `
      .concat(generating_report_names.join(", ")))
    }
    if (logout) {
      let path = '/users/accounts/login/'
      let options = {
        method: 'DELETE',
        mode: 'cors',
        withCredentials: true,
        headers: {
          "Authorization": "Token " + this.props.token,
        }
      };

      fetch(url + ':' + CORE_PORT + path, options).then((res) => {
        if (res.ok) {
          // console.log('logout response received');
          // console.log(res);
          this.props.changeState({
            email: '',
            first_name: '',
            last_name: '',
            token: '',
            user_type: '',
          })
        }
      }).catch((e) => (
        console.log(e)
      ));
    }
  }

  render() {
    return (
      this.props.token === "" ?
        <Redirect to="/"></Redirect>
        :
        <nav id='navigation-bar' className='nav-bar'>
          <Link to="/project">
            <div className='logo'>
              <img className="logo-img" src={Korotu_logo} />
            </div>
          </Link>
          <div className='nav-links'>
            <div className='ppframe'>
              <div className='ppbox'>
                {/* <Link to='/payment'>
            <button className='paymentbutton'>  &nbsp;&nbsp;Payment </button>
          </Link> */}
                <Link to='/project'>
                  <button className='projectbutton'>Projects </button>
                </Link>
              </div>
            </div>

            <NotificationIcon
              first_name={this.props.first_name}
              last_name={this.props.last_name}
              email={this.props.email}
              project_Id={this.props.projectID}
            />



          </div>

          <UserIcon
            first_name={this.props.first_name}
            last_name={this.props.last_name}
            email={this.props.email}
            userLogout={this.userLogout}
            user_type={this.user_type}
            token={this.token}
          />

        </nav>
    );
  }
}


/**
 * User icon for nav bar
 * 
 */
export function UserIcon(props) {
  let [isOpen, setIsOpen] = useState(false);
  let [openDelay, setOpenDelay] = useState(false);
  const iconRef = useRef();
  const handleOpening = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(async () => {
    if (isOpen === true) {
      setOpenDelay(true);
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
      setOpenDelay(false);
    }
  }, [isOpen]);



  return (

    <div className='user-icon' ref={iconRef}>
      <button onClick={handleOpening} style={{ borderRadius: '50%', color: 'white', height: 'auto', width: '2.5em', backgroundColor: 'transparent', borderColor: 'white', }}>
        {props.first_name.split(" ").map((n) => n[0]).join("")}
      </button>
      {openDelay &&
        (
          <UserMenu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            iconRef={iconRef}
            {...props}
          />
        )}
    </div>
  )
}

export function UserMenu(props) {
  let [height, setHeight] = useState(0);
  const boxRef = useRef();
  useEffect(() => {
    if (props.isOpen) setHeight(boxRef.current.getBoundingClientRect().height);
    else setHeight(0);
  }, [props.isOpen]);

  useEffect(() => {
    function outsideClick(event) {
      if (props.iconRef.current && !props.iconRef.current.contains(event.target)) {
        props.setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    };
  }, [boxRef]);

  return (
    <div className='user-menu-container' style={{ height: height }}>
      <div ref={boxRef}>
        <div className='user-menu-profile-container'>
          <div className='user-account-circle'>
            {(props.first_name + ' ' + props.last_name).split(" ").map((n) => n[0]).join("")}
          </div>
          <div>
            <div>{props.first_name + ' ' + props.last_name}</div>
          </div>
          <div>
            {props.email}
          </div>
          <div className='user-menu-version-info'>
            {/* Needs to be manually updated */}
            <span className='product-names'>CarbonWatch</span>
            <span className='product-version-numbers'>v1.3</span>
            <span className='product-names'>GrasslandWatch</span>
            <span className='product-version-numbers'>v2.3</span>
            <span className='product-names'>ForestChangeWatch</span>
            <span className='product-version-numbers'>v2.0</span>
          </div>
        </div>
        <Link to="/contact">
          <UserMenuItem><img src={User} className="profile" /> Contact Us</UserMenuItem>
        </Link>
        <UserMenuItem onClick={props.userLogout}><img src={SignoutDark} className="signout" /> Sign out</UserMenuItem>
        <div className='cancel-button' onClick={() => props.setIsOpen(false)}>Cancel</div>
      </div>
    </div>
  )
}

export function UserMenuItem(props) {
  return (
    <div className='user-menu-item' onClick={props.onClick}>
      {props.children}
    </div>
  )
}


/**
 * Bell Icon for the Nav Bar
 * 
 */

export function NotificationIcon(props) {
  let [isOpen, setIsOpen] = useState(false);
  let [openDelay, setOpenDelay] = useState(false);
  let [notifications_list, setNotifications_List] = useState([]);
  let [unreadNotifs, setunreadNotifs] = useState([]);
  const iconRef = useRef();
  const history = useHistory();

  function compare(a, b) {
    var dateA = new Date(formatDateTime(a.creation_date))
    var dateB = new Date(formatDateTime(b.creation_date))
    // console.log("a_creation_date: ", a.creation_date)
    // console.log("dateA: ", dateA)

    if ( dateA < dateB ){
      return 1;
    }
    if ( dateA > dateB ){
      return -1;
    }
    return 0;
  }
  
  // Format the creation_date in "YYYY-MM-DD HH:mm:ss" for easy sorting
  function formatDateTime(str) {
    let formatedDateTime = "";

    const DateArray = str.substring(0, 10).split("/");
    const TimeStr = str.substring(11, 19);
    const DateTimeArray = DateArray.concat(TimeStr);

    formatedDateTime = DateTimeArray[2] + "-" + DateTimeArray[1] + "-" + DateTimeArray[0] + " " + DateTimeArray[3];
    return formatedDateTime;
  }


  const handleOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(async () => {
    let userEmail = JSON.parse(sessionStorage.getItem('webState')).email;

    getProjectNotifications(account_id_global)
      .then((res) => {
        setNotifications_List(res)
      })

    if (isOpen === true) {
      setunreadNotifs([]);
      let tempArr = [];

      notifications_list.forEach((notifications) => {
        notifications.forEach((notification) => {
          tempArr.push(notification)
        })
      })

      tempArr.sort(compare)
      // console.log("tempArr", tempArr)

      tempArr.forEach((notification) => {
          // console.log("notification", notification)
          let elem = (
            <div className='notification' onClick={async () => {
              handleOpening()
              const projectID = notification.project
              if (notification.content == 'mowing') {
                // Correct the routing for forest reports marked by mowing historically 
                const projectData = await getProjectDetails(projectID)
                const serviceType = projectData.service
                if (serviceType == 'forest-change') {
                  history.push(`/project/${projectID}/view-forestchange`)
                  history.go(0)
                } else {
                  history.push(`/project/${projectID}/mowingdisplay`)
                  history.go(0)
                }
              } else if (notification.content == 'forest-change') {
                history.push(`/project/${projectID}/view-forestchange`)
                history.go(0)
              }
              else {
                history.push(`/project/${projectID}/reports`)
                history.go(0)
              }
            }}>
              <div className='notification--content'>
                <div><strong>{`The report "${notification.title}" was generated.`}</strong></div>
                <div><p>{`${notification.creation_date.slice(0, 16)} UTC`}</p></div>
              </div>
              {/* <div className='divider'></div> */}
            </div>)
          setunreadNotifs(oldArr => [...oldArr, elem]) 
        })
      setOpenDelay(true);
    } else {
      setOpenDelay(false);
    }
  }, [isOpen]);

  // useEffect(async () => {
  //   let userEmail = JSON.parse(localStorage.getItem('webState')).email;

  //   let result = getProjectNotifications(account_id_global)
  //   result.then((result) => {
  //     console.log("result", result)
  //   })
    
  //     setunreadNotifs([]);
  
  //     notifications_list.forEach((notification) => {
  //       console.log("notification", notification)
  //         let elem = (
  //           <div className='notification' onClick={() => {
  //             handleOpening()
  //             history.push(`/project/${notification.project}/gediView`)
  //             history.go(0)
  //           }}>
  //             <div className='notification--content'>
  //               <div><strong>{`The report "${notification.title}" was generated.`}</strong></div>
  //               <div><p>{`${notification.creation_date.slice(0, 16)} UTC`}</p></div>
  //             </div>
  //             <div className='divider'></div>
  //           </div>)
  //         setunreadNotifs(oldArr => [...oldArr, elem])
  //       })

  //     console.log("finished getting")
  //     // await new Promise(resolve => setTimeout(resolve, 500));
  //     if (isOpen === true) {
  //       setOpenDelay(true);
  //     } else {
  //       setOpenDelay(false);
  //     }


  // }, [isOpen]);

  return (
    <div className='bellicon' ref={iconRef}>
      <button onClick={handleOpening} style={{ borderRadius: '0%', color: 'white', height: '2.5em', width: '2.5em', backgroundColor: 'transparent', border: "transparent" }}>
        <img src={bell} className="bell" />
      </button>
      {openDelay &&
        (
          <NavigationMenu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            iconRef={iconRef}
            unreadNotifs={unreadNotifs}
            {...props}
          />
        )}
    </div>
  )
}


export function NavigationMenu(props) {


  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [projectID, setProjectID] = useState("")
  let [height, setHeight] = useState(0);
  const boxRef = useRef();
  useEffect(() => {
    if (props.isOpen) setHeight(boxRef.current.getBoundingClientRect().height);
    else setHeight(0);
  }, [props.isOpen]);

  useEffect(() => {
    function outsideClick(event) {
      if (props.iconRef.current && !props.iconRef.current.contains(event.target)) {
        props.setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    };
  }, [boxRef]);


  return (
    <div className='notification-menu-container' style={{ height: height }}>
      <div ref={boxRef}>
        <div className='user-menu-profile-container'>

          {/* <h1>Unread</h1> */}
          {
            <div>
              {(props.unreadNotifs.length > 0) ? props.unreadNotifs : <h1>No Notifications</h1>}
            </div>
          }
          {
            /* <h1> Read</h1>
                {<div>
                {readNotifs}
            </div>} */
          }

        </div>
      </div>
    </div>
  )
}



export default NavigationBar;