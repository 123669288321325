import React, { useState } from "react";
import { useRouteMatch, useParams, Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  downloadSatelliteImage,
  getMDReport,
  getMDReportCSV,
  getSatelliteImageDetails,
} from "../apis/landsteward/accounts";
import {
  downloadHarmonized,
  downloadSentinelSat2,
} from "../apis/landsteward/satellite";
import { CreateForestReport } from "../apis/landsteward/forest-change"

import { Dropdown } from "../components/modules/satellite-dropdown";

import "./forest-page.css";
import "./satellite-image-create-page.css";
import "./mowing-create-page.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import SimpleImageSlider from "react-simple-image-slider";
import { AutoDisplayPage } from "./automated-display-page";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";

export function ForestPage(props) {
  let match = useRouteMatch();
  let params = useParams();
  let project = props.searchState("projects", "projectID", params.projectID);

  console.log(
    `URL params SatelliteCreate: ${params.projectID}||${params.propertyID}`
  );
  console.log(props.project)
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement
  );

  // region is props.project.region

  return (
    <div className="satellite-image-create-page">
      <div className="satellite-image-create-page-title" id="mowing-title">
        <h2>Generate Forest Detection Report</h2>
      </div>

      <p className="metadata-headings" id="mowing-headings">
        <strong>Project: </strong>
        <span style={{ fontWeight: "normal" }}>
          {project ? project.name : ""}
        </span>
      </p>

      <ForestReportForm
      />
    </div>
  );
}


export function ForestReportForm(props) {
  let params = useParams();
  const history = useHistory();
  const [name, setName] = useState('');
  const reportNameMaxLength = 30;
  const [satBand, setSatBand] = useState("NDVI");
  const [yearOfInterest, setYearOfInterest] = useState("2024");
  const [reportStartYear, setReportStartYear] = useState("2021");
  const [reportStartDate, setReportStartDate] = useState("2021-01-01");
  const [warning, setWarning] = useState('');
  const [reportEndDate, setReportEndDate] = useState("2024-12-31");

  const [newImageID, setNewImageID] = useState("");
  const [downloadStatus, setDownloadStatus] = useState("");
  const [spatialRes, setSpatialRes] = useState(10);

  async function triggerDownload(e) {
    e.preventDefault();

    if (name.trim() === "") {
      setWarning("Report name cannot be empty.");
      return;
    }
    
    setDownloadStatus("pending");
    let reportID;
    let cloudMask = true;
    let spatialResolution = 30;
    let mowingID = null;
    let update = false;
    let email = JSON.parse(sessionStorage.getItem('webState')).email

    console.log('reportStartDate: ', reportStartDate)
    console.log('reportEndDate: ', reportEndDate)
    console.log('spatialRes: ', spatialRes)

    reportID = await CreateForestReport(
      name,
      params.projectID,
      reportStartDate,
      reportEndDate,
      spatialRes,
      cloudMask,
      mowingID,
      email,
      update
    );
    if (reportID == null) {
      console.log('Mowing failed')
      setWarning('Report creation failed')
      setMowingReportInProgress(false)
    } else {
      // if everything goes well up until the job is queued, redirect to the project page
      history.push({
        pathname: `/project/${params.projectID}/view-forestchange`,
        // state: {spatialRes: spatialRes}
      })
    }
  }

  function setDates(yoi) {
    setYearOfInterest(yoi);
    let startYear = Number(yoi) - 3;
    let startDate = String(startYear) + '-01-01';
    let endDate = Number(yoi) + '-12-31'
    setReportStartYear(startYear);
    setReportStartDate(startDate);
    setReportEndDate(endDate);
  }

  return (
    <form className="forest-form">
      {/** Satellite Form Details */}

      <div className="satellite-image-form__details" id="mowing-details">
        <label htmlFor="newName" className="fcw-form-label">
          <p className="label-text"><b>Report Name:</b></p>
        </label>
        <input
          className="name-input"
          name="newName"
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          maxLength={reportNameMaxLength}
        />
        {/* <p
          className={name.length === reportNameMaxLength ? 'mowing_text-limit-colour' : undefined}
          style={{ marginTop: '10px' }}
        >
          {name.length}/{reportNameMaxLength}
        </p> */}

        <label htmlFor="spatial-res" className="fcw-form-label">
          <p className="label-text"><b>Image Source:</b></p>
        </label>
        <select
          name="spatial-res"
          id="spatial-res"
          className="options-dropdown"
          onChange={(event) => {
            setSpatialRes(() => {
              let spatialRes = event.target.options[event.target.options.selectedIndex].value
              return spatialRes
            })
          }}
        >
          <option value={10}>Sentinel-2 (10m resolution)</option>
          <option value={30}>HLS (30m resolution)</option>
        </select>

        <label htmlFor="satellite-image-band" className="fcw-form-label">
          <p className="label-text"><b>Comparison Type:</b></p>
        </label>
        <input
          className="static-input-box"
          type="text"
          value={satBand}
          name="newName"
          onChange={(event) => setSatBand(event.target.value)}
          disabled
        />

        <br></br>
        <br></br>
        <span>Report Dates</span>

        <label htmlFor="year-of-interest" className="fcw-form-label">
          <p className="label-text"><b>Year of Interest:</b></p>
        </label>
        {/* <input
          className="selectable-input-box"
          type="number"
          min="2018"
          max="2023"
          step="1"
          value={yearOfInterest}
          name="year-of-interest"
          onChange={(event) => setDates(event.target.value)}
        /> */}
        <select
          name="year-of-interest"
          id="year-of-interest"
          className="options-dropdown"
          onChange={(event) => setDates(event.target.value)}
        >
          <option value={2024}>2024</option>
          <option value={2023}>2023</option>
          <option value={2022}>2022</option>
          <option value={2021}>2021</option>
          <option value={2020}>2020</option>
          <option value={2019}>2019</option>
          <option value={2018}>2018</option>
        </select>

        <label htmlFor="satellite-image-end-date" className="fcw-form-label">
          <p className="label-text"><b>Report Range:</b></p>
        </label>
        <input
          className="static-input-box"
          type="text"
          value={reportStartYear + ' - ' + yearOfInterest}
          name="satellite-image-end-date"
          disabled
        />
        <br />
        <p className="report-range-explanation">
          Your report range is from your year of interest <br></br>
          to 3 years before it. You can compare your year <br></br>
          of interest with dates within this range.
        </p>

        <br />

      </div>
      <div className="button-for-mowing">
        <button
          onClick={triggerDownload}
          style={{
            visibility:
              (downloadStatus === "pending" && "hidden") ||
              (newImageID && "hidden"),
          }}
        >
          {" "}
          Create Report{" "}
        </button>
      </div>
      <div className="empty-name-warning">
      <p className='project-new__warning-text'>{warning}</p>
      </div>

      {downloadStatus === "failed" && (
        <p className="processing-text">
          {" "}
          Oh no! Something went wrong, please try again later
        </p>
      )}
      {/* {(downloadStatus === 'pending') && <p className="processing-text"> The download you triggered is processing</p>} */}
      {newImageID && (
        <span>
          <p className="processing-text">
            Your satellite image has finished downloading!
          </p>
          <Link
            to={`/project/${params.projectID}/property/${params.propertyID}/satellite_image/${newImageID}`}
          >
            <div className="create-satellite-button">
              <button> View Image </button>
            </div>
          </Link>
        </span>
      )}
    </form>
  );
}